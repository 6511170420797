import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import slugify from "../lib/slugify"
import Marquee from "react-fast-marquee"
import { affiliates } from "./EquipmentThumbnail"

import InfoPill from "./InfoPill"

const ExerciseThumbnail = ({
  id,
  name,
  type,
  target,
  equipment,
  category,
  video_url,
  thumbnail_url,
  setHoveredMovement,
  overlay = true,
  showAffiliate = false,
  autoplay,
  onLoad,
}) => {
  const [opacity, setOpacity] = useState(0)
  const [playMarquee, setPlayMarquee] = useState(false)

  useEffect(() => {
    const video = document.getElementById(`exercise-hero-${id}`)
    if (video) {
      video.muted = true
      video.setAttribute("playsinline", true)
      if (autoplay) {
        video.setAttribute("autoplay", true)
        video.setAttribute("loop", true)
      }

      var poster = video.poster
      if (poster) {
        var image = new Image()
        image.onload = function () {
          setOpacity(1)
        }
        image.src = poster
      }
    }
  }, []) //[autoplay]

  const onMouseOver = () => {
    const video = document.getElementById(`exercise-hero-${id}`)
    if (video) {
      if (setHoveredMovement) setHoveredMovement({ id, name })
      // Show loading animation.
      var playPromise = video.play()
      if (name?.length > 32) setPlayMarquee(true)

      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          })
      }
    }
  }

  const onMouseLeave = () => {
    const video = document.getElementById(`exercise-hero-${id}`)
    if (video) {
      if (setHoveredMovement) setHoveredMovement(null)
      var pausePromise = video.pause()
      setPlayMarquee(false)

      if (pausePromise !== undefined) {
        pausePromise.then(_ => {}).catch(error => {})
      }
    }
  }

  return (
    <>
      <Container
        onMouseOver={autoplay ? () => {} : onMouseOver}
        onMouseLeave={autoplay ? () => {} : onMouseLeave}
        transition={{ duration: 1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity }}
        className="container"
      >
        <Link to={`/exercises/${slugify(name)}/${id}`} state={{ modal: true }}>
          <Video
            id={`exercise-hero-${id}`}
            poster={thumbnail_url}
            controlsList="nodownload"
          >
            <source src={video_url} type="video/mp4" />
          </Video>
        </Link>
        {overlay && (
          <Overlay>
            {showAffiliate &&
            equipment &&
            equipment.length &&
            affiliates[equipment[0].name] ? (
              <a
                href={affiliates[equipment[0].name].url}
                target="_blank"
                className="affiliate"
              >
                <h6>Buy {affiliates[equipment[0].name].copy} ↗</h6>
              </a>
            ) : (
              <div />
            )}
            <div className="pills">
              <InfoPill invert>{type || "Not Set"}</InfoPill>
              <InfoPill invert>{target || "Not Set"}</InfoPill>
              <InfoPill invert>{category || "Not Set"}</InfoPill>
            </div>
            <Marquee
              play={playMarquee}
              pauseOnHover={!playMarquee}
              speed={40}
              gradient={false}
            >
              <h5>{name}</h5>
            </Marquee>
          </Overlay>
        )}
      </Container>
    </>
  )
}

const Container = styled(motion.div)`
  position: relative;
  font-size: 0;
  &:hover {
    .pills {
      margin-left: 2px;
      opacity: 1;
    }

    h3 {
      opacity: 1;
    }
  }
  @media (max-width: 36em) {
    &:hover {
      .pills {
        opacity: 0;
      }
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 16px;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  pointer-events: none;

  > div:first-child {
    height: 36px;
  }

  .affiliate {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid #eee;
    border-radius: 20px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    text-decoration: none;
    pointer-events: auto;
    padding: 4px 10px 3px 8px;
    z-index: 100;
    h6 {
      color: black;
    }
  }

  .pills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.2;
    transition: 0.4s all;
  }

  h5 {
    margin: 0;
    color: white;
    font-weight: normal;
    padding-left: 4px;
    transition: 0.4s all;
    opacity: 0.9;
  }

  @media (max-width: 36em) {
    .pills {
      opacity: 0;
    }

    h3 {
      font-size: 18px;
    }
  }
`

const Video = styled(motion.video)`
  width: 100%;
  border-radius: 12px;
`

export default ExerciseThumbnail
