import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion, useAnimation, useViewportScroll } from "framer-motion"
import shuffleLetters from "shuffle-letters"
import styled from "styled-components"
import { useGoal } from "gatsby-plugin-fathom"

import ExerciseThumbnail from "../components/ExerciseThumbnail"
import Button from "../components/Button"

function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY,
  }

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight,
  }

  let reference = referenceElement.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  const response = {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY:
      (position.y - offset.top - offset.height / 2) / (offset.height / 2),
  }

  return response
}

const HomeHero = () => {
  const data = useStaticQuery(graphql`
    query {
      programme {
        movements {
          id
          name
          type
          category
          target
          thumbnail_url
          video_url
        }
      }
    }
  `)

  const gridRef = useRef()
  const [movements, setMovements] = useState([])
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
    centerX: 0,
    centerY: 0,
  })
  const [hoveredMovement, setHoveredMovement] = useState(null)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const background = useAnimation()
  const midground = useAnimation()
  const foreground = useAnimation()
  const text = useAnimation()

  useEffect(() => {
    setTimeout(() => setShouldAnimate(true), 2000)
  }, [])

  useEffect(() => {
    if (shouldAnimate) {
      background.start(i => ({
        x: mousePosition.centerX * -300,
        y: mousePosition.centerY * -300,
        opacity: 0.6,
      }))
      midground.start(i => ({
        x: mousePosition.centerX * -340,
        y: mousePosition.centerY * -340,
        opacity: 0.8,
      }))
      foreground.start(i => ({
        x: mousePosition.centerX * -380,
        y: mousePosition.centerY * -380,
      }))
      text.start(i => ({
        x: mousePosition.centerX * -400,
        y: mousePosition.centerY * -400,
      }))
    }
  }, [mousePosition, shouldAnimate])

  useEffect(() => {
    const shuffled = data.programme.movements.sort(() => 0.5 - Math.random())
    setMovements(shuffled.slice(0, 10))
  }, [data])

  useEffect(() => {
    if (hoveredMovement) {
      shuffleLetters(document.getElementById("shuffle"), {
        iterations: 8,
        fps: 45,
      })
    }
  }, [hoveredMovement])

  const scrollToStory = () => {
    const story = document.getElementById(`programme-story`)
    story.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    })
  }

  const transition = { type: "spring", stiffness: 30 }

  return (
    <Container>
      <Grid
        ref={gridRef}
        rendered={movements.length}
        onPointerMove={e => {
          const coords = getRelativeCoordinates(e, gridRef.current)
          if (coords.width > 800) setMousePosition(coords)
        }}
        onMouseLeave={e =>
          setMousePosition({
            x: 0,
            y: 0,
            centerX: 0,
            centerY: 0,
          })
        }
      >
        {hoveredMovement && (
          <HoveredMovement
            transition={{ duration: 0 }}
            animate={{
              x: mousePosition.x + 18,
              y: mousePosition.y + 6,
            }}
          >
            PRGM_MVMT.<span id="shuffle">0{hoveredMovement.id}</span>
          </HoveredMovement>
        )}

        <TextContainer>
          <motion.h1 transition={transition} animate={text}>
            <div>
              <span>
                Programme is a workout app that plans every workout for you.
              </span>
            </div>
          </motion.h1>

          <motion.h4 transition={transition} animate={foreground}>
            <span>
              {/* Programme makes it easier to get stronger, faster, by planning and progressing every workout for you so you’re always moving in the right direction. */}
              Programme learns from your past workouts, training experience and
              available equipment to create your optimal workout plan that
              adapts to your progress.
            </span>
          </motion.h4>

          <Buttons
            rendered={movements.length}
            transition={transition}
            animate={text}
          >
            <span>
              <Button
                accent
                to="https://apps.apple.com/us/app/programme/id1554594236/"
              >
                Download on iOS
              </Button>

              <div style={{ cursor: "pointer" }} onClick={scrollToStory}>
                <Button secondary>Learn more</Button>
              </div>
            </span>
          </Buttons>
        </TextContainer>

        {movements.length && (
          <>
            <GradientIn />

            <motion.div
              className="pos pos-1"
              transition={transition}
              animate={midground}
            >
              <ExerciseThumbnail
                {...movements[0]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-2"
              transition={transition}
              animate={midground}
            >
              <ExerciseThumbnail
                {...movements[1]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-3"
              transition={transition}
              animate={foreground}
            >
              <ExerciseThumbnail
                {...movements[2]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-4"
              transition={transition}
              animate={foreground}
            >
              <ExerciseThumbnail
                {...movements[3]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-5"
              transition={transition}
              animate={foreground}
            >
              <ExerciseThumbnail
                {...movements[4]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-6"
              transition={transition}
              animate={midground}
            >
              <ExerciseThumbnail
                {...movements[5]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-7"
              transition={transition}
              animate={foreground}
            >
              <ExerciseThumbnail
                {...movements[6]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-8"
              transition={transition}
              animate={midground}
            >
              <ExerciseThumbnail
                {...movements[7]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <motion.div
              className="pos pos-9"
              transition={transition}
              animate={background}
            >
              <ExerciseThumbnail
                {...movements[8]}
                setHoveredMovement={setHoveredMovement}
                overlay={false}
              />
            </motion.div>

            <GradientOut>
              <img
                alt="Arrow"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFMAAAA0CAYAAAD2QwTiAAAACXBIWXMAADddAAA3XQEZgEZdAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIfSURBVHgB7ZvhbcIwEEZvhI7ACB2BEdgANmg3SDZoN4BNGAE28AiM8NWmSRshQnzn2GcnfhL/sMM9IkKegKiSDgDvVPlD7MMu3OOXhirOR9P52EsXogoN8PFkYc8nrRA798eIj2ZqYYPX8E7xwhl81IEl1ENkz5ZWgJ1z5+mjeVx4hD83LPwq7+br5vTl2C/0fQdWIdTOtQFP5J3hBifwMe7AtCA6kQZ8vh83WrXQAJGnsQ3P4GNKFxog8vpq0zf7uICPcWupQLqZDfhcJ2cO2PyCwoR2s17Bx3jPCvlpf6aCCBC54RwnROiRCgC879ZykYMDLlKofX1f4GMQeqEF/26gJ8vSBP/b5iE3zPWNZSlCA0TOe7dnN9xCRhZChSIdO4qB3fgAGaotFONNcooDxcSJgQyVForpJjlGmhPAHqiFjC0lBLIi5mgpJUKhydId5BfNljTIVSiETVJN5OCFZ5XuMFeT1CIXoZi7SWoB5RaKGE1SCyi2UMRsklpAoYUiRZPUAolbKFI1SS2QKN0hdZPUIrZQaDVJLRAp3UG7SWoxt1Dk0iS1wEwtFLk1SS0Q2EKRa5PUAvIWeoSMZf9AF/IWyqWlNZBAaEtrIqLQltYIZOnuFXk0SS1mFHqiiriFDsmvSWoBeQu9i0Shvw2NBmQt1FSRI4BXmtzzNlQZx1NoFenLhNAqkguep7vym6QWD0IX/zfD6OC/hWbdJH8AURp2X+nF5R0AAAAASUVORK5CYII="
              />
            </GradientOut>
          </>
        )}
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1000;
  @media (max-width: 48em) {
    display: none;
  }
`

const TextContainer = styled.div`
  position: absolute;
  width: 700px;
  height: 300px;
  left: calc(50% - 350px);
  top: calc(50% - 150px);
  z-index: 100;
  @media (max-width: 640px) {
    left: 20vw;
    width: 100vw;
    padding: 0 1em;
    top: calc(50% - 240px);
  }
`

const Buttons = styled(motion.div)`
  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    transition: 1s all ease-in-out;
    opacity: ${({ rendered }) => (rendered ? 1 : 0)};
    @media (max-width: 36em) {
      flex-direction: column;
      align-items: center;

      & > a {
        margin-bottom: 1em;
      }
    }
  }
`

const Grid = styled(motion.div)`
  position: relative;
  background: black;
  width: 140vw;
  height: 140vh;
  margin-left: -20vw;
  margin-top: -20vh;
  display: grid;
  grid-template-columns: repeat(50, 2%);
  grid-template-rows: repeat(50, 2%);

  h1,
  h4 {
    div {
      overflow: hidden;
    }
    text-align: center;
    font-weight: normal;
    margin: 0;
    pointer-events: none;
  }

  h1 span {
    color: var(--white);
    position: relative;
    line-height: 1.2;
    font-size: 48px;
    transition: 1s all ease-in-out;
    opacity: ${({ rendered }) => (rendered ? 1 : 0)};
    // transition: 1.4s all;
    // top: ${({ rendered }) => (rendered ? 0 : 64)}px;
  }

  // h1 div:last-child span {
  //   transition-delay: 0.1s;
  // }

  h4 {
    padding: 0.5em 3em;
  }

  h4 span {
    color: var(--light-grey);
    transition: 1s all ease-in-out;
    opacity: ${({ rendered }) => (rendered ? 1 : 0)};
  }

  .pos-1 {
    grid-area: 5 / 15;
  }

  .pos-2 {
    grid-area: 40 / 40;
  }

  .pos-3 {
    grid-area: 24 / 38;
  }

  .pos-4 {
    grid-area: 8 / 28;
  }

  .pos-5 {
    grid-area: 38 / 24;
  }

  .pos-6 {
    grid-area: 24 / 5;
  }

  .pos-7 {
    grid-area: 40 / 10;
  }

  .pos-8 {
    grid-area: 6 / 41;
  }

  .pos-9 {
    grid-area: 10 / 1;
  }

  @media (max-width: 48em) {
    .pos-1 {
      grid-area: 0 / 15;
    }

    .pos-2 {
      grid-area: 32 / 38;
    }

    .pos-3 {
      grid-area: 24 / 38;
    }

    .pos-4 {
      grid-area: 15 / 28;
    }

    .pos-5 {
      grid-area: 36 / 20;
    }

    .pos-6 {
      grid-area: 24 / 1;
    }

    .pos-7 {
      grid-area: 33 / 1;
    }

    .pos-8 {
      grid-area: 8 / 34;
    }

    .pos-9 {
      grid-area: 14 / 5;
    }
  }

  .pos {
    grid-row-end: span 10;
    grid-column-end: span 10;
    z-index: 0;
    .container {
      transition: 1s all;
      transition-delay: 1.2s;
      opacity: ${({ rendered }) => (rendered ? 1 : 0)};
    }

    @media (max-width: 800px) {
      grid-row-end: span 15;
      grid-column-end: span 15;
      opacity: 0.6;
    }
  }

  @media (max-width: 36em) {
    h1 {
      font-size: 28px;
    }
    h4 {
      font-size: 20px;
    }
    .pos {
      display: none;
    }
  }
`

const HoveredMovement = styled(motion.div)`
  color: #f7f5f2;
  width: 200px;
  font-size: 12px;
  margin-top: -6px;
  font-family: DiatypeMono !important;
  z-index: 100;

  span {
    font-size: 12px;
    font-family: DiatypeMono !important;
  }
`

const GradientIn = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 1) 25%, 50%, rgba(0, 0, 0, 0));
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 5vh;
  height: 20vh;
  z-index: 150;
`

const GradientOut = styled.div`
  background: linear-gradient(rgba(0,0,0,0) 25%, 75%, rgba(0,0,0,1));
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 15vh;
  height: 20vh;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    margin-bottom: 8vh;
    width 14px;
  }
`

export default HomeHero
