import React, { createRef } from "react"
import styled from "styled-components"
import StoryPage from "./StoryPage"
import StoryVideo from "./StoryVideo"

const VideoStory = ({ pages }) => {
  const pagesRef = pages.map(x => createRef(null));

  return (
    <Container id="programme-story" style={{ height: `calc(100vh * ${pages.length})` }}>
      <Pages style={{ height: `calc(100vh * ${pages.length})` }}>
        {pages.map((page, index) => (
          <StoryPage key={`page-story-${index}`} {...page} ref={pagesRef[index]} />
        ))}
      </Pages>
      <Videos>
        {pages.map((page, index) => (
          <StoryVideo key={`video-story-${index}`} {...page} ref={pagesRef[index]} index={index} />
        ))}
      </Videos>
    </Container>
  )
}

const Pages = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
`

const Videos = styled.div`
  position: sticky;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 0;
`

const Container = styled.div`
  width: 100vw;
`
export default VideoStory
