import React, { forwardRef } from "react"
import styled from "styled-components"
import { motion, useScroll, useTransform } from "framer-motion"

const StoryPage = forwardRef(({content, videoUrl}, ref) => {
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start 50vh", "end 50vh"]
  })

  const opacity = useTransform(
    scrollYProgress, 
    [0, 0.1, 0.3, 0.7, 0.9, 1], 
    [0, 0, 1, 1, 0, 0]
  )

  return (
    <Section ref={ref}>
      <Content style={{ opacity }}>{content}</Content>
    </Section>
  )
})

const Section = styled(motion.section)``

const Content = styled(motion.div)`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  z-index: 1;
  overflow: hidden;

  h1 {
    text-align: center;
    font-weight: normal;
    margin: 0;
    font-size: 42px;
    max-width: 800px;
    color: var(--white);
    position: relative;
    line-height: 1.25;
    z-index: 1;
  }

  @media (max-width: 48em) {
    h1 {
      font-size: 28px;
    }
  }
`

export default StoryPage
