import styled from "styled-components";

const InfoPill = styled.div`
  display: inline;
  font-family: DiatypeMono;
  font-size: 14px;
  line-height: 14px;
  color: ${({ invert }) => invert ? "white" : "black"};
  text-transform: uppercase;

  border: 1px solid ${({ invert }) => invert ? "white" : "black"};
  border-radius: 16px;
  padding: 4px 8px 2px 8px;
  margin-inline-end: 4px;
  margin-block-end: 4px;

  white-space: nowrap;
  flex-wrap: nowrap;
`

export default InfoPill;
