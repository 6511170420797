import React from "react"
import styled from "styled-components"
import Button from "./Button"
import MobileHomeImage from "../images/mobile-home-image.jpg"

const MobileHomeHero = () => (
  <Container>
    <H1>Programme is a workout app that plans every workout for you</H1>
    <Image src={MobileHomeImage} alt="Image of Programme's Mobile App" />
    <Button
      accent
      to="https://apps.apple.com/us/app/programme/id1554594236/"
      style={{
        width: "calc(100% - 80px)",
        margin: "0 20px",
        position: "absolute",
        bottom: "40px",
      }}
    >
      Download on iOS
    </Button>
    <Teaser>
      <span>See how it works</span>
      <img
        alt="Arrow"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFMAAAA0CAYAAAD2QwTiAAAACXBIWXMAADddAAA3XQEZgEZdAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIfSURBVHgB7ZvhbcIwEEZvhI7ACB2BEdgANmg3SDZoN4BNGAE28AiM8NWmSRshQnzn2GcnfhL/sMM9IkKegKiSDgDvVPlD7MMu3OOXhirOR9P52EsXogoN8PFkYc8nrRA798eIj2ZqYYPX8E7xwhl81IEl1ENkz5ZWgJ1z5+mjeVx4hD83LPwq7+br5vTl2C/0fQdWIdTOtQFP5J3hBifwMe7AtCA6kQZ8vh83WrXQAJGnsQ3P4GNKFxog8vpq0zf7uICPcWupQLqZDfhcJ2cO2PyCwoR2s17Bx3jPCvlpf6aCCBC54RwnROiRCgC879ZykYMDLlKofX1f4GMQeqEF/26gJ8vSBP/b5iE3zPWNZSlCA0TOe7dnN9xCRhZChSIdO4qB3fgAGaotFONNcooDxcSJgQyVForpJjlGmhPAHqiFjC0lBLIi5mgpJUKhydId5BfNljTIVSiETVJN5OCFZ5XuMFeT1CIXoZi7SWoB5RaKGE1SCyi2UMRsklpAoYUiRZPUAolbKFI1SS2QKN0hdZPUIrZQaDVJLRAp3UG7SWoxt1Dk0iS1wEwtFLk1SS0Q2EKRa5PUAvIWeoSMZf9AF/IWyqWlNZBAaEtrIqLQltYIZOnuFXk0SS1mFHqiiriFDsmvSWoBeQu9i0Shvw2NBmQt1FSRI4BXmtzzNlQZx1NoFenLhNAqkguep7vym6QWD0IX/zfD6OC/hWbdJH8AURp2X+nF5R0AAAAASUVORK5CYII="
      />
    </Teaser>
  </Container>
)

const Container = styled.div`
  width: 100vw;
  position: relative;
  display: none;
  padding-top: 70px;
  @media (max-width: 48em) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const H1 = styled.h1`
  font-size: 30px;
  color: white;
  font-weight: normal;
  text-align: center;
  padding: 0 20px;
`

const Image = styled.img`
  width: calc(100% - 40px);
  margin-top: 30px;
`

const Teaser = styled.div`
  width: 100%;
  position: absolute;
  bottom: -70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  z-index: 100;
  span {
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
  }
  img {
    width: 16px;
    opacity: 0.4;
  }
`

export default MobileHomeHero
