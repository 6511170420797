import React, { forwardRef } from "react"
import styled from "styled-components"
import { motion, useScroll, useTransform } from "framer-motion"

const StoryVideo = forwardRef(({ videoUrl, videoOpacity = 0.25, index }, ref) => {
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`start 50vh`, `end 50vh`]
  })

  const opacity = useTransform(
    scrollYProgress, 
    [0, 0.1, 0.9, 1], 
    [index === 0 ? videoOpacity : 0, videoOpacity, videoOpacity, 0]
  )

  return (
    <Video style={{ opacity, borderRadius: index === 0 ? "40px 40px 0 0" : "0" }} autoPlay loop muted playsInline>
      <source src={videoUrl} type="video/mp4" />
    </Video>
  )
})


const Video = styled(motion.video)`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`

export default StoryVideo
