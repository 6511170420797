const slugify = input => {
  return input
    ?.trim()
    .toLowerCase()
    .replace(/[+()&.,?]/g, "")
    .replace(/ - /g, " ")
    .replace(/  /g, " ")
    .replace(/ /g, "-")
}

export default slugify
