import React from "react"
import styled from "styled-components"

const affiliateParam = "a_aid=65ce2d584af77"

export const affiliates = {
  "Pull Up Bar": {
    copy: "Pull Up Bars",
    url:
      "https://www.roguefitness.com/crossfit-equipment/top-products/pull-up-rigs-and-bars" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Bodyweight%20and%20Gymnastics/Pull-up%20Systems%20/XX9885/XX9885-TH_wspcag.png",
  },
  Kettlebell: {
    copy: "Kettlebells",
    url:
      "https://www.roguefitness.com/conditioning/strength-equipment/kettlebells" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Conditioning/Strength%20Equipment/Kettlebells/AU-USC001/Cadillac-US-Kettlebell-TH_asupzx.png",
  },
  Rings: {
    copy: "Rings",
    url:
      "https://www.roguefitness.com/bodyweight-gymnastics/gymnastics/rings" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Bodyweight%20and%20Gymnastics/Gymnastics%20/Rings/XX416/XX416-TH_glbpjo.png",
  },
  Dumbbell: {
    copy: "Dumbbells",
    url:
      "https://www.roguefitness.com/bodyweight-gymnastics/gymnastics/rings" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Conditioning/Strength%20Equipment/Dumbbells/XX7125/XX7125-TH_gdzn7s.png",
  },
  Barbell: {
    copy: "Barbells",
    url:
      "https://www.roguefitness.com/weightlifting-bars-plates/barbells" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Weightlifting%20Bars%20and%20Plates/Barbells/Mens%2020KG%20Barbells/28MMOLYWLCERAKOTE/28MMOLYWLCERAKOTE-TH_h7haky.png",
  },
  Plate: {
    copy: "Plates",
    url:
      "https://www.roguefitness.com/weightlifting-bars-plates/bumpers" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Weightlifting%20Bars%20and%20Plates/Plates/Bumper%20Plates/WE0003/WE0003-TH_sffgsu.png",
  },
  Landmine: {
    copy: "Landmines",
    url:
      "https://www.roguefitness.com/search?q=Landmine" + "&" + affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Strength%20Equipment/Strength%20Training/Upper%20Body%20Training/RA0025/RA0025-TH_ohvpfn.png",
  },
  Box: {
    copy: "Boxes",
    url:
      "https://www.roguefitness.com/conditioning/plyo-boxes" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Conditioning/Plyo%20Boxes%20/Foam%20Plyoboxes/IP0093/IP0093-th_qama7p.png",
  },
  Band: {
    copy: "Bands",
    url:
      "https://www.roguefitness.com/mobility-rehab/mobility-tools/bands" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Mobility/Mobility%20Tools%20/Bands/ECHOBANDS/ECHOBANDS-TH_tdykms.png",
  },
  "Loop Band": {
    copy: "Loop Bands",
    url:
      "https://www.roguefitness.com/mobility-rehab/mobility-tools/bands" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Strength%20Equipment/Training%20Accessories%20/Bands/LOOPBANDS/LOOPBANDS-TH_dmjtt4.png",
  },
  Sliders: {
    copy: "Sliders",
    url: "https://www.roguefitness.com/sklz-slidez" + "?" + affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Conditioning/Speed%20and%20Agility/Core%20and%20Stability/SLDS001/SLDS001-TH_idkgks.png",
  },
  Rack: {
    copy: "a Rack",
    url:
      "https://www.roguefitness.com/rogue-rigs-racks/squat-stands" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Rigs%20and%20Racks/Squat%20Stands/Monster%20Lite%20Squat%20Stands/XX7993/XX7993-th_b4qbzb.png",
  },
  Bench: {
    copy: "a Bench",
    url:
      "https://www.roguefitness.com/strength-equipment/strength-training/benches" +
      "?" +
      affiliateParam,
    image:
      "https://assets.roguefitness.com/f_auto,q_auto,c_fill,g_center,w_500,h_500,b_rgb:f8f8f8/catalog/Strength%20Equipment/Strength%20Training/Weight%20Benches/Flat%20Utility%20Benches/RA1362/RA1362-Textured-Pad-TH_fcy8kh.png",
  },
  TRX: {
    copy: "a TRX",
    url: "https://www.trxtraining.com/collections/suspension-trainers",
    image:
      "https://www.trxtraining.com/cdn/shop/products/TRXPro45-21-21v21341_R2_1_300x.jpg?v=1663964638",
  },
}

const EquipmentThumbnail = ({ name }) => {
  return affiliates[name] ? (
    <Container
      href={affiliates[name].url}
      target="_blank"
      className="container"
    >
      <div>
        <img alt={affiliates[name].copy} src={affiliates[name].image} />
        <h6>Buy {affiliates[name].copy} ↗</h6>
      </div>
    </Container>
  ) : null
}

const Container = styled.a`
  display: block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #eee;
  border-radius: 20px;
  text-decoration: none !important;
  padding: 8px;
  z-index: 100;
  margin-right: 12px;
  margin-bottom: 12px;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }

  img {
    min-width: 160px;
    width: 160px;
    height: 160px;
    border-radius: 12px;
  }

  h6 {
    color: black;
    margin-left: 8px;
  }
`

export default EquipmentThumbnail
