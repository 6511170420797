import React from "react"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"
import HomeHero from "../components/HomeHero"
import MobileHomeHero from "../components/MobileHomeHero"
import Story from "../components/Story"
import Button from "../components/Button"

const pages = [
  {
    content: <h1>Programme builds all your workouts from the ground up — optimized for your goals, experience and equipment.</h1>,
    videoUrl:
      "https://p001.nyc3.digitaloceanspaces.com/site/Bruin_010.mp4",
  },
  {
    content: <h1>As you progress, Programme learns from your results and adapts your workouts so you're always moving in the right direction.</h1>,
    videoUrl:
      "https://p001.nyc3.digitaloceanspaces.com/site/Bruin_006.mp4",
  },
  {
    content: <h1>So your results go from stagnant to soaring.</h1>,
    videoUrl:
      "https://p001.nyc3.digitaloceanspaces.com/site/Bruin_001.mp4",
  },
  {
    content: (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h1>Get your optimal workout plan today.</h1>
        <Button
          accent
          to="https://apps.apple.com/us/app/programme/id1554594236/"
          style={{ maxWidth: 440, marginTop: 20 }}
        >
          Download on iOS
        </Button>
      </div>
    ),
    videoUrl:
      "https://p001.nyc3.digitaloceanspaces.com/site/Bruin_003.mp4",
    videoOpacity: 0.4,
  },
]

const Home = () => {
  return (
    <div>
      <Metadata
        title="Programme"
        description="Programme is a workout app that plans every workout for you – based on your progress, equipment and lifestyle."
        url="https://programme.app/"
      />
      <HomeHero />
      <MobileHomeHero />
      <Story pages={pages} />
    </div>
  )
}

Home.Layout = Layout;
export default Home
